/* Section Container */
.about-us-section {
  background: linear-gradient(to bottom, #3b0b29 0%, #ff6600 100%);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.property-developers{
  background-color: #fffefc !important;
}
/* Background Overlay */
.about-us-section::before {
  content: '';
  /* background-image: url('https://img-cdn.pixlr.com/image-generator/history/65bb506dcb310754719cf81f/ede935de-1138-4f66-8ed7-44bd16efc709/medium.webp'); */
  background-color: #ff6600;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.3;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Background Video */
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

/* Title Styling */
.about-us-title {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  position: relative;
  z-index: 1;
  font-size: 4rem; /* Adjust the font size as per requirement */
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  animation: scaleText 2s ease-out forwards;
  transform-origin: center;
}

.about-us-title-container {
  opacity: 0;
  transition: opacity 2s ease-in; /* Adjust duration as needed */
}

.about-us-title-container.reveal {
  opacity: 1;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .about-us-title {
      font-size: 2rem;
  }
  .about-us-section {
      height: 200px;
  }
}

/* Property Developers Section */
.property-developers {
  background-color: #f9f9f9;
  padding-top: 60px;
  padding-bottom: 60px;
}

.property-developers h1,
.property-developers h2 {
  font-weight: bold;
  letter-spacing: 1px;
}

.property-developers .text-warning {
  color: #ff6600 !important;
}
.text-uppercase{
  color: #ff6600 !important;
}

/* Mission & Vision Section */
.mission-vision h3 {
  color: #333;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.mission-vision p {
  font-size: 1.1rem;
}



/* Paragraph Styling */
p {
  line-height: 1.6;
}



/* Sticky Video Column */
.sticky-col {
  position: sticky;
  top: 120px; /* Adjusted to provide slight padding from the top */
  align-self: flex-start;
  padding: 10px;
  /* background-color: #ff6600; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px; */
  z-index: 1;
  height: fit-content; /* Ensure it only takes the necessary height */
}

/* Video Content in Sticky Column */
.video-content {
  width:40vw;
  height:60vh;
  /* border-radius: 8px; */
  object-fit: cover;
  mix-blend-mode:light;
  /* border:none !important; */

   /* Hide the play controls */
   pointer-events: none; 
   overflow: hidden;
  
}

.video-content {
  border: none; /* Removes border on the video itself */
}

.sticky-col {
  border: none; /* Removes border on the container (if any) */
}






/* Sticky Video Column */
.sticky-col {
  position:sticky; /* Initial position */
  padding: 10px;
  /* background-color: #11ff00; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px; */
  left:65%;
  
}

/* Sticky Class Applied Dynamically */
.is-sticky {
  position: fixed;
  margin-top: 9%;
  
  top: 20px;
  left:57%; /* Sticks 20px from the top */
  width: 30%; /* Adjust width to match the column */
  z-index: 10;
}



/* count */

.impact-section {
  text-align: center;
  padding: 2rem;
  background:#ff6600;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #ffffff;
  position: relative;
  overflow: hidden;
}

.impact-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ff6600;
  z-index: 1;
}

.impact-section h2,
.impact-item {
  position: relative;
  z-index: 2;
}

.impact-section .highlight {
  color:#3F070E;
}

.impact-stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 9rem; /* Adds equal space between items */
}

.impact-item {
  flex: 1 1 150px;
  max-width: 150px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.impact-value {
  display: flex;
  align-items: center;
  justify-content: center; /* Centers the items horizontally */
  gap: 0.5rem; /* Equal spacing between number and plus sign */
  font-size: 2.5rem; /* Larger font size for the number and plus */
  font-weight: bold;
  color:#3F070E;
  
}

.count {
  font-size: 2.5rem;
}

.plus {
  font-size: 2rem;
}

.label {
  font-size: 1.2rem;
  color: #ffffff;
  margin-top: 0.5rem; /* Space between number and label */
}

@media (max-width: 768px) {
  .impact-item {
    max-width: 100px;
  }
}
@media (max-width: 768px) {
  .mission-vision {
      margin-top: 40px;
  }
  .video-content {
    width: 80vw;
    height:auto;
    border-radius: 8px;
    object-fit:contain;
    mix-blend-mode:light;
   
    
  }
}


/* Responsive Adjustments */
@media (max-width: 1200px) {
  .is-sticky {
    width: 35%; /* Slightly increase width for medium screens */
  }

  .about-us-title {
    font-size: 2.5rem; /* Reduce title size */
  }
}

@media (max-width: 992px) {
  .is-sticky {
    width: 40%; /* Adjust width for smaller screens */
  }

  .about-us-title {
    font-size: 2rem; /* Further reduce title size */
  }

  .property-developers {
    padding-top: 30px; /* Reduce padding */
    padding-bottom: 30px; /* Reduce padding */
  }
}

@media (max-width: 768px) {
  .is-sticky {
    width: 100%; /* Full width for mobile */
    position: static; /* Change to static for smaller screens */
    margin-top: 20px; /* Add margin on top for spacing */
  }

  .about-us-section {
    height: 250px; /* Reduce height on smaller screens */
  }
  .background-video{
    height: 300px;
  }
  .about-us-title {
    font-size: 1.8rem; /* Reduce title size */
  }

  .services {
    min-height: 800px; /* Ensure enough height for scrolling on mobile */
  }
}

@media (max-width: 576px) {
  .about-us-title {
    font-size: 1.8rem; /* Further reduce title size for extra small screens */
  }
}