.admin{
    display: flex;
}

.edit{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    
}
.remove{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
}
@media(max-width:800px) {
    .admin{
        flex-direction: column;
        font-size: 10px;
    }
}