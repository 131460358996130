.sidebar{
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    gap: 20px;
    width:100%;
    max-width: 250px;
    height: 200vh;
    background: #ff6700;
}

.sidebar-item{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 20px;
    padding:5px 10px;
    border-radius: 6px;
    background: #f6f6f6;
    gap:11px;
    cursor: pointer;
    color: orangered;
    font-size: 20px;
    font-weight: 700;
}
.sidebar-item p{
    display: flex;
    color: black;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
}
.sidebar-item1{
    font-size: 25px;
}
@media(max-width:800px){
    .sidebar{
        padding: 30px 10px;
        flex-direction: row;
        width: 100%;
        max-width: none;
        height: auto;
        justify-content: center;
    }
    .sidebar-item{
        margin: 0;
        font-size: 18px;
        font-weight: 200;
    }
    .sidebar-item p{
        font-size: 12px;
    }
}