@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

.project-title {
  font-size: 54px;
  font-weight: bold;
  color: #4c1d1d;
}

.map-container {
  height: 350px;
  background-color: #d3d3d3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.facility-image {
  width: 500px;
  height: 500px;
  border-radius: 10px;
}

.specification{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:15px;
}
.rowcontent{
    display: flex;
    justify-content: center;
    gap: 30px;
}
.pdf{
  display: flex;
  justify-content: center;
  align-items: center;
}
.pdf button{
  height: 50px;
  justify-content: center;
  align-items: center;
   font-size: 18px;
  font-weight: 600;
  background:  #ff6700;
  border: none;
  color: white;
  border-radius: 5px;
  padding: 15px;
  margin: auto;
 
}
.pdf button:hover{
  background:  #fb7218;
  box-shadow: #8f8d8d 0px 8px 10px;
}

@media(max-width:800px) {
  .facility-image {
    width: 400px;
    height: 500px;
    justify-content: center;
    margin: auto;
    padding: 0px 20px;
    border-radius: 10px;
  }
}
